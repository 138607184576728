import { CountryData, CountryIso2, ParsedCountry } from '../../types'
import { parseCountry } from './parse-country'

const constructFieldNotSupportedErrorMessage = (field: keyof ParsedCountry) => {
  return `Field "${field}" is not supported`
}

export const getCountry = ({
  value,
  field,
  countries,
  defaultCountry,
}: {
  value: CountryData[number]
  field: keyof ParsedCountry
  countries: CountryData[]
  defaultCountry: CountryIso2
}): ParsedCountry | undefined => {
  if (['regions', 'priority'].includes(field)) {
    throw new Error(constructFieldNotSupportedErrorMessage(field))
  }
  const country =
    countries.find(country => {
      const parsedCountry = parseCountry(country)
      return value === parsedCountry[field]
    }) ||
    countries.find(country => {
      const parsedCountry = parseCountry(country)
      return defaultCountry.toLocaleLowerCase() === parsedCountry[field]
    })

  if (!country) return undefined
  return parseCountry(country)
}
