import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CloseIcon from 'shared/icons/close-icon'
import { defaultCountries } from 'shared/react-international-phone/constants/default-countries'
import { buildClassNames } from '../../style/build-class-names'
import { CountryData, CountryIso2, ParsedCountry } from '../../types'
import { parseCountry, scrollToChild } from '../../utils'
import { FlagEmoji } from '../flag-emoji/flag-emoji'
import './country-selector-dropdown.module.css'

export interface CountrySelectorDropdownStyleProps {
  style?: React.CSSProperties
  className?: string

  listStyle?: React.CSSProperties
  listClassName?: string

  searchInputStyle?: React.CSSProperties
  searchInputClassName?: string

  clearSearchInputStyle?: React.CSSProperties
  clearSearchInputClassName?: string

  listItemStyle?: React.CSSProperties
  listItemClassName?: string

  listItemFlagStyle?: React.CSSProperties
  listItemFlagClassName?: string

  listItemCountryNameStyle?: React.CSSProperties
  listItemCountryNameClassName?: string

  listItemDialCodeStyle?: React.CSSProperties
  listItemDialCodeClassName?: string
}

export interface CountrySelectorDropdownProps extends CountrySelectorDropdownStyleProps {
  show: boolean
  dialCodePrefix?: string
  selectedCountry: CountryIso2
  countries?: CountryData[]
  onSelect?: (country: ParsedCountry) => void
  onClose?: () => void
  searchInputPlaceholder?: string
}

export const CountrySelectorDropdown: React.FC<CountrySelectorDropdownProps> = ({
  show,
  dialCodePrefix = '+',
  selectedCountry,
  countries = defaultCountries,
  onSelect,
  onClose,
  searchInputPlaceholder,
  ...styleProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const lastScrolledCountry = useRef<CountryIso2>()

  const [query, setQuery] = useState<string>('')

  const filteredCountries = useMemo(() => {
    if (query) {
      const queryLowerCase = query.toLowerCase()
      return countries.filter(([country]) => country.toLowerCase().includes(queryLowerCase))
    } else {
      return countries
    }
  }, [countries, query])

  const getCountryIndex = useCallback(
    (country: CountryIso2) => {
      return filteredCountries.findIndex(c => parseCountry(c).iso2 === country)
    },
    [filteredCountries],
  )

  const [focusedItemIndex, setFocusedItemIndex] = useState(getCountryIndex(selectedCountry))

  const resetFocusedItemIndex = () => {
    if (lastScrolledCountry.current === selectedCountry) return
    setFocusedItemIndex(getCountryIndex(selectedCountry))
  }

  const handleCountrySelect = useCallback(
    (country: ParsedCountry) => {
      setFocusedItemIndex(getCountryIndex(country.iso2))
      onSelect?.(country)
    },
    [onSelect, getCountryIndex],
  )

  const moveFocusedItem = (to: 'prev' | 'next' | 'first' | 'last') => {
    const lastPossibleIndex = filteredCountries.length - 1

    const getNewIndex = (currentIndex: number) => {
      if (to === 'prev') return currentIndex - 1
      if (to === 'next') return currentIndex + 1
      if (to === 'last') return lastPossibleIndex
      return 0
    }

    setFocusedItemIndex(v => {
      const newIndex = getNewIndex(v)
      if (newIndex < 0) return 0
      if (newIndex > lastPossibleIndex) return lastPossibleIndex
      return newIndex
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()

    if (e.key === 'Enter') {
      const focusedCountry = parseCountry(filteredCountries[focusedItemIndex])
      handleCountrySelect(focusedCountry)
      return
    }

    if (e.key === 'Escape') {
      onClose?.()
      return
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      moveFocusedItem('prev')
      return
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      moveFocusedItem('next')
      return
    }

    if (e.key === 'PageUp') {
      e.preventDefault()
      moveFocusedItem('first')
      return
    }

    if (e.key === 'PageDown') {
      e.preventDefault()
      moveFocusedItem('last')
      return
    }

    if (e.key === ' ') {
      // prevent scrolling with space
      e.preventDefault()
    }
  }

  const scrollToFocusedCountry = useCallback(() => {
    if (!listRef.current || focusedItemIndex === undefined || !filteredCountries[focusedItemIndex])
      return

    const focusedCountry = parseCountry(filteredCountries[focusedItemIndex]).iso2
    if (focusedCountry === lastScrolledCountry.current) return

    const element = listRef.current.querySelector(`[data-country="${focusedCountry}"]`)
    if (!element) return
    scrollToChild(listRef.current, element as HTMLElement)

    lastScrolledCountry.current = focusedCountry
  }, [focusedItemIndex, filteredCountries])

  // Scroll to focused item on change
  useEffect(() => {
    scrollToFocusedCountry()
  }, [focusedItemIndex, scrollToFocusedCountry])

  useEffect(() => {
    if (!inputRef.current) return

    if (show) {
      // Autofocus on open dropdown
      Promise.resolve().then(() => inputRef.current?.focus())
    } else {
      resetFocusedItemIndex()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  // Update focusedItemIndex on selectedCountry prop change
  useEffect(() => {
    resetFocusedItemIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  return (
    <div
      className={buildClassNames({
        addPrefix: ['country-selector-dropdown'],
        rawClassNames: [styleProps.className],
      })}
      style={{ display: show ? 'block' : 'none', ...styleProps.style }}
      tabIndex={-1}
      aria-activedescendant={`${
        filteredCountries[focusedItemIndex] &&
        parseCountry(filteredCountries[focusedItemIndex]).iso2
      }-option`}
      onBlur={onClose}
    >
      <input
        ref={inputRef}
        className={buildClassNames({
          addPrefix: ['country-selector-dropdown__search-input'],
          rawClassNames: [styleProps.searchInputClassName],
        })}
        placeholder={searchInputPlaceholder || ''}
        style={styleProps.searchInputStyle}
        type="text"
        value={query}
        onChange={e => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {query && (
        <CloseIcon
          onClick={() => setQuery('')}
          className={buildClassNames({
            addPrefix: ['country-selector-dropdown__clear-search-input'],
            rawClassNames: [styleProps.clearSearchInputClassName],
          })}
          style={styleProps.clearSearchInputStyle}
        />
      )}
      <ul
        ref={listRef}
        role="listbox"
        className={buildClassNames({
          addPrefix: ['country-selector-dropdown__list'],
          rawClassNames: [styleProps.listClassName],
        })}
        style={styleProps.listStyle}
      >
        {filteredCountries.map((c, index) => {
          const country = parseCountry(c)
          const isSelected = country.iso2 === selectedCountry
          const isFocused = index === focusedItemIndex

          return (
            <li
              key={country.iso2}
              data-country={country.iso2}
              data-focused={isFocused}
              role="option"
              aria-selected={isSelected}
              aria-label={`${country.name} ${dialCodePrefix}${country.dialCode}`}
              id={`${country.iso2}-option`}
              className={buildClassNames({
                addPrefix: [
                  'country-selector-dropdown__list-item',
                  isSelected && 'country-selector-dropdown__list-item--selected',
                  isFocused && 'country-selector-dropdown__list-item--focused',
                ],
                rawClassNames: [styleProps.listItemClassName],
              })}
              onClick={() => handleCountrySelect(country)}
              style={styleProps.listItemStyle}
            >
              <FlagEmoji
                iso2={country.iso2}
                className={buildClassNames({
                  addPrefix: ['country-selector-dropdown__list-item-flag-emoji'],
                  rawClassNames: [styleProps.listItemFlagClassName],
                })}
                style={styleProps.listItemFlagStyle}
              />
              <span
                className={buildClassNames({
                  addPrefix: ['country-selector-dropdown__list-item-country-name'],
                  rawClassNames: [styleProps.listItemCountryNameClassName],
                })}
                style={styleProps.listItemCountryNameStyle}
              >
                {country.name}
              </span>
              <span
                className={buildClassNames({
                  addPrefix: ['country-selector-dropdown__list-item-dial-code'],
                  rawClassNames: [styleProps.listItemDialCodeClassName],
                })}
                style={styleProps.listItemDialCodeStyle}
              >
                {dialCodePrefix}
                {country.dialCode}
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
